import { Box, Grid } from "@mui/material"
import CardComp from "../CardComp/CardComp"
import { FaTrophy } from "react-icons/fa"
import Slider from "react-slick"

const settings = {
  infinite: true,
  arrows: true,
  slidesToScroll: 1,
  slidesToShow: 11,
  responsive: [
    {
      breakpoint: 800, // screen width at which settings will change
      settings: {
        slidesToShow: 5, // number of slides to show when screen width is <= 800px
      },
    },
  ],
}

const AndarBharResult = ({ result }) => {
  const splitByStar = result[0]?.cards?.split("*")
  const finalResult = splitByStar.map(item => item.split(","))

  return (
    <div className="casino-result-modal">
      <div className="casino-result-round-id">
        <span>
          <b>Round Id: </b> {result[0]?.mid}
        </span>
      </div>

      <Grid container style={{margin:"12px 0px"}}>
        <Grid item xs={12} md={12}>
          <div className="three-card-result-container">
            <div className="text-center">Andar</div>
            <div className="three-card-result result_slick" style={{width:"100%"}}>
              <Slider {...settings} >
                {finalResult[0]?.map((item, id) => {
                  if (item === "") return null
                  return <CardComp key={id} shown={true} card={item} />
                })}
              </Slider>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="w-50 three-card-result-container">
            <div className="text-center">Bahar</div>
            <div className="three-card-result result_slick">
              <Slider {...settings} >
                {finalResult[1]?.map((item, id) => {
                  if (item === "") return null
                  return <CardComp key={id} shown={true} card={item} />
                })}
              </Slider>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* <Box>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={6}>
            <div className="casino-result-desc">
              <div className="casino-result-desc-item">
                <div>Winner:</div>
                <div>{result[0]?.win === "1" ? "Player A" : "Player B"}</div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box> */}
    </div>
  )
}

export default AndarBharResult
