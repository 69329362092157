import { Grid } from "@mui/material"
import React, { useState } from "react"
import TigerMob from "./TigerMob"
import Lion from "./Lion"
import clsx from "clsx"

const casinoName = ["Dragon", "Tiger", "Lion"]

const DTLMobile = ({ t2Dl, t2DR, t2Tl, t2TR, t2Ll, t2LR, handleClick }: any) => {
    const [active, setActive] = useState(0)



    const handleActive = (val: number) => {
        setActive(val)
    }

    return (
        <div>
            <ul className="dtl_nav_pills" role="tablist">
                {casinoName?.map((item, id) => (
                    <li
                        className="nav-item"
                        role="presentation"
                        onClick={() => handleActive(id)}
                    >
                        <button
                            type="button"
                            className={`nav-link ${active === id ? "active" : ""}`}
                        >
                            {item}
                        </button>
                    </li>
                ))}
            </ul>
            {
                active === 0 && <Grid
                    container
                    sx={{
                        marginTop: "7px",
                    }}
                >
                    <Grid xs={6}>
                        <div className="casino-table-left-box">

                            <div className="casino-table-body">
                                {t2Dl?.map(items => {
                                    return (
                                        <div className="casino-table-row">
                                            <div className="casino-nation-detail">
                                                <div className="casino-nation-name">
                                                    {items?.nat?.includes("Black") ? (
                                                        <>
                                                            Black{" "}
                                                            <span className="card-icon ms-1">
                                                                <img
                                                                    src={`/img/hukum.png`}
                                                                    alt=""
                                                                    className="small_icon_image_card"
                                                                    style={{ width: "10px", height: "auto" }}
                                                                />
                                                                <img
                                                                    src={`/img/cdee.png`}
                                                                    alt=""
                                                                    className="small_icon_image_card"
                                                                    style={{ width: "10px", height: "auto" }}
                                                                />
                                                            </span>
                                                        </>
                                                    ) : items?.nat.includes("Red") ? (
                                                        <>
                                                            Red
                                                            <span className="card-icon ms-1">
                                                                <span className="card-red ">
                                                                    <img
                                                                        src={`/img/pan.png`}
                                                                        alt=""
                                                                        className="small_icon_image_card"
                                                                        style={{ width: "10px", height: "auto" }}
                                                                    />
                                                                    <img
                                                                        src={`/img/eat.png`}
                                                                        alt=""
                                                                        className="small_icon_image_card"
                                                                        style={{ width: "10px", height: "auto" }}
                                                                    />
                                                                </span>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        "Winner"
                                                    )}
                                                </div>
                                            </div>
                                            <div className={clsx(
                                                "casino-odds-box1 back suspended-box",
                                                !(items?.gstatus === true) && "suspended"
                                            )} onClick={() => handleClick(items)}>
                                                <span className="casino-odds">{items?.b1}</span>
                                                <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={items?.pnl >= 0 ? "text-success" : "text-danger"}>{items?.pnl}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={6}>
                        <div className="casino-table-right-box">
                            <div className="casino-table-body">
                                {t2DR?.map(items => {
                                    return (
                                        <div className="casino-table-row">
                                            <div className="casino-nation-detail">
                                                <div className="casino-nation-name">
                                                    {items?.nat?.includes("Odd") ? "Odd" : "Even"}
                                                </div>
                                            </div>
                                            <div className={clsx(
                                                "casino-odds-box1 back suspended-box",
                                                !(items?.gstatus === true) && "suspended"
                                            )} onClick={() => handleClick(items)}>
                                                <span className="casino-odds">{items?.b1}</span>
                                                <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={items?.pnl >= 0 ? "text-success" : "text-danger"}>{items?.pnl}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            }
            {
                active === 1 && <TigerMob t2Tl={t2Tl} t2TR={t2TR} handleClick={handleClick} />
            }
            {
                active === 2 && <Lion t2Ll={t2Ll} t2LR={t2LR} handleClick={handleClick} />
            }

        </div>
    )
}

export default DTLMobile
