import "./video.scss";
import { useEffect, useState } from "react";
import Card2OnVideo from "../DT20/Card2OnVideo/Card2OnVideo";
import FlipClockCountdown from "../clock/FlipClockCountDown";
import { videoIdById } from "../Constant/Constant";
import { useParams } from "react-router-dom";
import CardOnVideo from "../CardOnVideo/CardOnVideo";
import AndarBaharCardOnVideo from "../andarBahar/AndarBaharCardOnVideo";
import SingleCardOnVideo from "../aaa/SingleCardOnVideo";

const Video = ({ t1, t3 }: any) => {
  const { id, tableId } = useParams();
  const [first, setFirst] = useState(0);

  useEffect(() => {
    if (first === 0 && Number(t1.autotime)) {
      setFirst(new Date().getTime() + Number(t1?.autotime) * 1000);
    } else if (t1.autotime == "0" && first !== 0) {
      setFirst(0);
    }
    return () => { };
  }, [t1?.autotime]);

  console.log(id, "3e23eqedqwdw")


  const videoData = {
    "52": "3035",
    "55": "3041",
    "53": "3058",
    "54": "3056",
    "51": "3030",
    "61": "3047",
    "60": "3053"
  };




  const cardOnVideoById = {
    "51": <CardOnVideo t1={t1} />,
    "52": <Card2OnVideo t1={t1} />,
    "54": <SingleCardOnVideo t1={t1} />,
    "60": <AndarBaharCardOnVideo t3={t3} />,
    "61": <Card2OnVideo t1={t1} />,
    "55": <SingleCardOnVideo t1={t1} />,
    "53": <SingleCardOnVideo t1={t1} />,
    "57": <CardOnVideo t1={t1} />,
  };
  return (
    <div className="video_block_container">
      <div className="video_iframe_container">
        {/* {
          id === "51" ? <iframe
            title="Hello"
            // src={`https://stream.1ex99.com/casinoVideo/video?id=${videoIdById[id]}`}
            src={`https://alpha-g.qnsports.live/route/mnh.php?id=${videoData[id]}`}

          ></iframe> : <iframe
            title="Hello"
            // src={`https://stream.1ex99.com/casinoVideo/video?id=${videoIdById[id]}`}
            src={`https://vrnl.xyz/?params=${videoData[tableId]}`}

          ></iframe>
        } */}

        <iframe
          title="Hello"
          // src="https://vrnl.xyz/?params=8010"
          src={`https://alpha-n.qnsports.live/route/mnh.php?id=${videoData[id]}`}

        ></iframe>

      </div>
      <p className="auto_Time">{t1.autotime}</p>
      {/* <FlipClockCountdown
        className="countdown_container"
        to={first}
        renderMap={[false, false, false, true]}
        showLabels={false}
      /> */}
      {t1 && cardOnVideoById[id]}
    </div>
  );
};

export default Video;
