import { Box, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import matkaImg from '../../../Img/comming.jpg'

const LedgerContainer = styled(Box)({
    paddingTop: "30px",
    backgroundColor: "#f1f0f5",
})


const Header = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "16px",
    "@media (max-width: 800px)": {
        display: "none",
    },
})

const Matka = () => {
    return (
        <LedgerContainer>
            <Header
                sx={{
                    pl: 4,
                    pr: 4,
                }}
            >
                <Typography
                    variant="h3"
                    className="mobileHide"
                    sx={{ fontFamily: "Bebas Neue", fontWeight: 700, fontSize: "28px" }}
                >
                    Matka
                </Typography>
            </Header>
            <Box>
                <img src={matkaImg} alt="" style={{
                    width: "98%",
                    height: "85vh"
                }} />
            </Box>


        </LedgerContainer>
    )
}

export default Matka