import { useState, type FC } from "react"
import cros from "../../Img/cros.png"
import MatchBetLedger from "./MatchBetLedger"
import type {
  Data123,
  DataBetLedger,
} from "../../store/service/userServices/user"
import SessionBetLedger from "./SessionBetLedger"
import CasinoContent from "./CasinoContent"
import moment from "moment"

interface Props {
  handleClose: () => void
  data: Data123 | undefined
  sportName: string
  casinoData: DataBetLedger
  casinoDataShow: boolean,
  casinoDate: string
}

const ModalsContent: FC<Props> = ({
  handleClose,
  data,
  sportName,
  casinoData,
  casinoDataShow,
  casinoDate
}) => {
  const [showMatchBet, setShowMatchBet] = useState(false)
  const [showSessionBet, setShowSessionBet] = useState(false)


  const handleMatchBet = () => {
    setShowMatchBet(true)
  }
  const handleSessionBet = () => {
    setShowSessionBet(true)
  }
  const handleBack = () => {
    setShowMatchBet(false)
    setShowSessionBet(false)
  }

  return (
    <div>
      {
        !casinoDataShow && <div className="main_popup">
        <h5 id="exampleModalLabel" className="popupTitle">
         {sportName}
        </h5>
        {!showMatchBet && !showSessionBet ? (
          <button
            type="button"
            aria-label="Close"
            className="close"
            onClick={handleClose}
          >
            <img src={cros} alt="cros" />
          </button>
        ) : (
          <button
            type="button"
            aria-label="Close"
            className="close"
            onClick={handleBack}
          >
            <img src={cros} alt="cros" />
          </button>
        )}
      </div>
      }
      
      {!casinoDataShow ? (
        <div className="modal-body">
          <div className="ng-star-inserted">
            <div className="popup-row">Date: {data?.date}</div>
            {showMatchBet && <MatchBetLedger ledgerData={data?.matchBets} />}
            {showSessionBet && (
              <SessionBetLedger ledgerData={data?.sessionBets} />
            )}
            <div
              className="popup-row"
              style={{ cursor: "pointer", color: "#2560ad" }}
              onClick={handleMatchBet}
            >
              Match Bets : {data?.matchBet}
            </div>
            <div className="popup-row" style={{ color: data && data?.matchWon > 0 ? "green" : "red" }}>
              {data?.matchWon > 0?"Match Won":"Match Loss"} : {data?.matchWon}
            </div>
            <div
              className="popup-row"
              style={{ cursor: "pointer", color: "#2560ad" }}
              onClick={handleSessionBet}
            >
              Session Bets : {data?.sessionBet}
            </div>
            <div
              className="popup-row"
              style={{ color: data && data?.sessionWon > 0 ? "green" : "red" }}
            >
              {data?.sessionWon > 0? "Session Won " : "Session Loss " } : {data?.sessionWon}
            </div>
           
            <div className="popup-row">Won By : {data?.wonBy}</div>
            <div
              className="popup-row"
            >
              Total Commission : {data?.totalCommission || 0}
            </div>
            <div className="popup-row" style={{ color: data && data?.totalWon > 0 ? "green" : "red" }}>
              WON Coins : {data?.totalWon}
            </div>
          </div>

          <div className="popup_Btn">
            <button
              type="button"
              className="popupBtn"
              onClick={handleClose}
              style={{ display: "block" }}
            >
              Close{" "}
            </button>
          </div>
        </div>
      ) : (
        <CasinoContent casinoData={casinoData} handleClose={handleClose} casinoDate={casinoDate}/>
      )}
    </div>
  )
}

export default ModalsContent
