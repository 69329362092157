import { type FC, useEffect, type ChangeEvent, useState } from "react"
import tvIcon from "../../../Img/tv_icon.png"
import { listenToThemeChange } from "../../../utils/themeEvent";
import Marquee from "react-fast-marquee"
import { IpRes } from "../../../store/service/odds/odds";


interface Props {
  isClassicMode: boolean
  setIsClassicMode: React.Dispatch<React.SetStateAction<boolean>>;
  id: string | undefined;
  claName: string,
  display?: string | undefined
  chanelId: string,
  ip: string,
  scoreId: string
}

const Scorecard: FC<Props> = ({ isClassicMode, setIsClassicMode, id, claName, display, chanelId, ip, scoreId }) => {
  const [themeColor, setThemeColor] = useState(localStorage.getItem("app-theme") || "purple-theme1");
  const [showTv, setShowTv] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);
  const host = window.location.hostname;
  useEffect(() => {
    const savedMode = localStorage.getItem("isClassicMode")
    if (savedMode !== null) {
      setIsClassicMode(JSON.parse(savedMode))
    }
  }, [])

  const handleSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    const newMode = e.target.checked
    setIsClassicMode(newMode)
    localStorage.setItem("isClassicMode", JSON.stringify(newMode))
  }
  const handleShowTv = () => {
    setShowTv(!showTv)
  }
  useEffect(() => {
    setIframeKey(prevKey => prevKey + 1);
  }, [showTv, id, scoreId, chanelId, ip]);
  useEffect(() => {
    listenToThemeChange(setThemeColor);
  }, []);
  return (
    <>
      <div className={`score ${claName}`}>
        <div className="toggles">
          <label className="switch">
            <input
              type="checkbox"
              onChange={handleSwitch}
              checked={isClassicMode}
            />
            <span className="slider round"></span>
          </label>
          <div className="Switch-to">Switch To {!isClassicMode ? "Classic Mode" : "New Mode"}</div>
        </div>
        <button className={`live-tv popupBtn ${themeColor}1`} onClick={handleShowTv}>
          <img src={tvIcon} className="livePlayBtn" alt="" />
          <span>View Tv</span>
        </button>
      </div>
      {
        (isClassicMode && display != "null") && <Marquee className="color_change" >
          {display}
        </Marquee>
      }


      {
        showTv ? (
          host?.includes("fastbet365") ? (
            <div className="tv-score-container">
              <iframe
                key={iframeKey}
                width="100%"
                className="tv-iframe live-iframe tv_iframe"
                title="score-iframe"
                src={`https://e765432.xyz/static/48efac116d4775729d8ee0cda4a0361df3d3c89b/getdata.php?chid=${chanelId}&ip=${ip}`}
              />
            </div>
          ) : (
            <div className="tv-score-container">
              <iframe
                key={iframeKey}
                width="100%"
                className="tv-iframe live-iframe"
                title="score-iframe"
                src={`https://100tun.online/web/${id}.html`}
              />
            </div>
          )
        ) : (
          ""
        )
      }


      <div className={isClassicMode ? "p-t-10" : ""}>
        {
          host?.includes("fastbet365") ? <iframe
            key={iframeKey + 1}
            title="iframe"
            height={"100%"}
            style={{ width: "100%" }}
            // src={`https://score.247idhub.com/index.html/event/${id}?theme=dark-wolf`}
            src={`https://scoredata.365cric.com/#/score5/${scoreId}`}
          /> : <iframe
            key={iframeKey + 1}
            title="iframe"
            height={100}
            style={{ width: "100%" }}
            src={`https://score.247idhub.com/index.html/event/${id}?theme=dark-wolf`}
          />
        }

      </div>
    </>
  )
}

export default Scorecard
