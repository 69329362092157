import { RouterProvider } from "react-router-dom"
import "./App.scss"
import Router from "./routes/PagesRoutes"
import "./pages/Dashboard/Dashboard.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'swiper/css';
import 'swiper/css/navigation';

const App = () => {
  return (
    <>
      <RouterProvider router={Router()} />
    </>
  )
}

export default App
