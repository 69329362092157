import { Grid } from '@mui/material'
import React from 'react'
import './style.scss'
import DTLMobile from './DTLMobile'
import clsx from 'clsx'

const DTL20 = ({ odds, setBetState, setShowBetSection, setOpen, setUpdated }: any) => {
    const { t2 } = odds;
    const handleClick = (data: any) => {
        if (data?.gstatus) {
            setOpen(true);
        }
        setBetState &&
            setBetState((prev: any) => ({
                ...prev,
                nation: data?.nat,
                casinoName: 2,
                isBack: true,
                odds: data?.b1,
                selectionId: data?.sid,
                colorName: "back",
            }));
        setShowBetSection(true);
        setUpdated(0)
    };
    return (
        <div className="t20_container dtl_container">
            <Grid container className='d_view'>
                <Grid md={6} xs={12}>
                    <div className="casino-table-left-box">
                        <div className="casino-table-header">
                            <div className="casino-nation-detail" />
                            <div className="casino-odds-box1 back">Dragon</div>
                            <div className="casino-odds-box1 back">Tiger</div>
                            <div className="casino-odds-box1 back">Lion</div>
                        </div>
                        <div className="casino-table-body">
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Winner</div>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[0]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[0])}>
                                    <span className="casino-odds">{t2[0]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[0]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[0]?.pnl}</p>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[18]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[18])}>
                                    <span className="casino-odds">{t2[18]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[18]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[18]?.pnl}</p>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[36]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[36])}>
                                    <span className="casino-odds">{t2[36]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[36]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[36]?.pnl}</p>
                                </div>
                            </div>
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">
                                        Black
                                        <img
                                            src={`/img/hukum.png`}
                                            alt=""
                                            className="small_icon_image_card"
                                            style={{ width: "10px", height: "auto" }}
                                        />
                                        <img
                                            src={`/img/cdee.png`}
                                            alt=""
                                            className="small_icon_image_card"
                                            style={{ width: "10px", height: "auto" }}
                                        />
                                    </div>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[1]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[1])}>
                                    <span className="casino-odds">{t2[1]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[1]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[1]?.pnl}</p>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[19]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[19])}>
                                    <span className="casino-odds">{t2[19]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[19]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[19]?.pnl}</p>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[37]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[37])}>
                                    <span className="casino-odds">{t2[37]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[37]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[37]?.pnl}</p>
                                </div>
                            </div>
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">
                                        Red
                                        <img
                                            src={`/img/pan.png`}
                                            alt=""
                                            className="small_icon_image_card"
                                            style={{ width: "10px", height: "auto" }}
                                        />
                                        <img
                                            src={`/img/eat.png`}
                                            alt=""
                                            className="small_icon_image_card"
                                            style={{ width: "10px", height: "auto" }}
                                        />
                                    </div>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[2]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[2])}>
                                    <span className="casino-odds">{t2[2]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[2]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[2]?.pnl}</p>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[20]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[20])}>
                                    <span className="casino-odds">{t2[20]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[20]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[20]?.pnl}</p>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[38]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[38])}>
                                    <span className="casino-odds">{t2[38]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[38]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[38]?.pnl}</p>
                                </div>
                            </div>


                        </div>
                    </div>

                </Grid>
                <Grid md={6} xs={12}>
                    <div className="casino-table-right-box">
                        <div className="casino-table-header">
                            <div className="casino-nation-detail" />
                            <div className="casino-odds-box1 back">Dragon</div>
                            <div className="casino-odds-box1 back">Tiger</div>
                            <div className="casino-odds-box1 back">Lion</div>
                        </div>
                        <div className="casino-table-body">
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Odd</div>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[3]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[3])}>
                                    <span className="casino-odds">{t2[3]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[3]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[3]?.pnl}</p>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[21]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[21])}>
                                    <span className="casino-odds">{t2[21]?.b1}</span >
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[21]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[21]?.pnl}</p>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[39]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[39])}>
                                    <span className="casino-odds">{t2[39]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[39]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[39]?.pnl}</p>
                                </div>
                            </div>
                            <div className="casino-table-row">
                                <div className="casino-nation-detail">
                                    <div className="casino-nation-name">Even</div>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[4]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[4])}>
                                    <span className="casino-odds">{t2[4]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[4]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[4]?.pnl}</p>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[22]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[22])}>
                                    <span className="casino-odds">{t2[22]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[22]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[22]?.pnl}</p>
                                </div>
                                <div className={clsx(
                                    "casino-odds-box1 back suspended-box",
                                    !(t2[40]?.gstatus === true) && "suspended"
                                )} onClick={() => handleClick(t2[40])}>
                                    <span className="casino-odds">{t2[40]?.b1}</span>
                                    <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={t2[40]?.pnl >= 0 ? "text-success" : "text-danger"}>{t2[40]?.pnl}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </Grid>
            </Grid>
            <div className='mob_view_casino'>

                <DTLMobile
                    handleClick={handleClick}
                    t2Dl={[t2[0], t2[1], t2[2]]}
                    t2DR={[t2[3], t2[4]]}
                    t2Tl={[t2[18], t2[19], t2[20]]}
                    t2TR={[t2[21], t2[22]]}
                    t2Ll={[t2[36], t2[37], t2[38]]} t2LR={[t2[39], t2[40]]}
                />
            </div>
        </div>
    )
}

export default DTL20