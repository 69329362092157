import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { titleById } from "../Constant/Constant";
import ResulTModalContent3Card from "./ResulTModalContent3Card";
import { useGetCasinoResyltByRoundIdMutation } from "../../../store/service/casino/casinoServices";
import CloseIcon from '@mui/icons-material/Close';
import AndarBharResult from "./AndarBharResult";
import DRAGONRules from "./DRAGONRules";
import AAAResult from "./AAAResult";
import './resultModalContent.scss'
import DTLResult from "./DTLResult";





const ResultModalContainer = ({ mid, tableId, open, setOpen }: any) => {
  const { id } = useParams()

  
  const [trigger, { data, isLoading }] = useGetCasinoResyltByRoundIdMutation()
  
  
  
  useEffect(() => {
    if(mid){
      trigger(mid);
    }
  }, [mid]);


  useEffect(() => {
    if (open) {
      document.querySelector("body")?.classList.add("modal-open-sus");
    } else {
      document.querySelector("body")?.classList.remove("modal-open-sus");
    }
    return () => { };
  }, [open]);

  return (
    <div>
      {isLoading && (
        <p className="place-lodder">
          <div>
            <i className="fa fa-spinner fa-spin"></i>
          </div>
        </p>
      )}
      <div>
        {open && (
          <div className="modal_overall">
            <div className="modal_overlay"></div>
            <div className={"modal_container lg" }>
              <div className="modal_header " style={{ background: "#2c3e50" }}>
                {`${titleById[tableId]} Result`}
                <span onClick={() => setOpen(false)} style={{cursor:"pointer"}}>
                  <CloseIcon />
                </span>
              </div>
              {(id === "51" || id === "57") && data?.data && (
                <ResulTModalContent3Card result={data?.data} />
              )}
              {(id === "60" ) && data?.data && (
                <AndarBharResult result={data?.data} />
              )}
              {id === "52" && data?.data && (
                <DRAGONRules result={data?.data} />
              )}
              {(id === "54" || id === "55" || id === "53") && data?.data && (
                <AAAResult id={id} result={data?.data} />
              )}
              {id === "61"  && data?.data && (
                <DTLResult id={id} result={data?.data} />
              )}
              
            </div>
          </div>
        )}
      </div>
      
    </div>
  );
};

export default ResultModalContainer;
