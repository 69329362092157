import { Grid } from '@mui/material'
import clsx from 'clsx'
import React from 'react'

const TigerMob = ({ t2Tl, t2TR, handleClick }: any) => {
    return (
        <Grid
            container
            sx={{
                marginTop: "7px",
            }}
        >
            <Grid xs={6}>
                <div className="casino-table-left-box">

                    <div className="casino-table-body">
                        {t2Tl?.map(items => {
                            return (
                                <div className="casino-table-row">
                                    <div className="casino-nation-detail">
                                        <div className="casino-nation-name">
                                            {items?.nat?.includes("Black") ? (
                                                <>
                                                    Black{" "}
                                                    <span className="card-icon ms-1">
                                                        <img
                                                            src={`/img/hukum.png`}
                                                            alt=""
                                                            className="small_icon_image_card"
                                                            style={{ width: "10px", height: "auto" }}
                                                        />
                                                        <img
                                                            src={`/img/cdee.png`}
                                                            alt=""
                                                            className="small_icon_image_card"
                                                            style={{ width: "10px", height: "auto" }}
                                                        />
                                                    </span>
                                                </>
                                            ) : items?.nat.includes("Red") ? (
                                                <>
                                                    Red{" "}
                                                    <span className="card-icon ms-1">
                                                        <span className="card-red ">
                                                            <img
                                                                src={`/img/pan.png`}
                                                                alt=""
                                                                className="small_icon_image_card"
                                                                style={{ width: "10px", height: "auto" }}
                                                            />
                                                            <img
                                                                src={`/img/eat.png`}
                                                                alt=""
                                                                className="small_icon_image_card"
                                                                style={{ width: "10px", height: "auto" }}
                                                            />
                                                        </span>
                                                    </span>
                                                </>
                                            ) : (
                                                "Winner"
                                            )}
                                        </div>
                                    </div>
                                    <div className={clsx(
                                        "casino-odds-box1 back suspended-box",
                                        !(items?.gstatus === true) && "suspended"
                                    )} onClick={() => handleClick(items)}>
                                        <span className="casino-odds">{items?.b1}</span>
                                        <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={items?.pnl >= 0 ? "text-success" : "text-danger"}>{items?.pnl}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Grid>
            <Grid xs={6}>
                <div className="casino-table-right-box">
                    <div className="casino-table-body">
                        {t2TR?.map(items => {
                            return (
                                <div className="casino-table-row">
                                    <div className="casino-nation-detail">
                                        <div className="casino-nation-name">
                                            {items?.nat?.includes("Odd") ? "Odd" : "Even"}
                                        </div>
                                    </div>
                                    <div className={clsx(
                                        "casino-odds-box1 back suspended-box",
                                        !(items?.gstatus === true) && "suspended"
                                    )} onClick={() => handleClick(items)}>
                                        <span className="casino-odds">{items?.b1}</span>
                                        <p style={{ padding: "0", margin: "0", fontSize: "12px" }} className={items?.pnl >= 0 ? "text-success" : "text-danger"}>{items?.pnl}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default TigerMob